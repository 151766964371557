<template>
    <v-card elevation="0" height="260" v-if="book.id">
        <v-card elevation="0" @click="goToBook">
            <v-row justify="center">
                <v-img
                    :src="`${$utils.globalConstants.BASE_ASSETS_URL}/lbr/image/${book.resourceDir}/cover_small.jpg`"
                    class="book-img border-radius-2 transition-all mx-2"
                    @load="handleLoaded"
                    :height="
                        this.$vuetify.breakpoint.xs ||
                        this.$vuetify.breakpoint.sm ||
                        this.$vuetify.breakpoint.md
                            ? '40vh'
                            : '25vh'
                    "
                    alt="Av"
                />
                <v-skeleton-loader
                    v-if="!loaded"
                    class="position-absolute opacity-none"
                    :height="
                        this.$vuetify.breakpoint.xs ||
                        this.$vuetify.breakpoint.sm ||
                        this.$vuetify.breakpoint.md
                            ? '40vh'
                            : '25vh'
                    "
                    width="120"
                    type="image"
                    :transition="'scale-transition'"
                />
            </v-row>

            <v-row justify="center" class="mt-2 mb-0 mx-4">
                <p class="text-center mb-2 truncate-overflow">
                    <v-tooltip bottom color="primary">
                        <template v-slot:activator="{ on, attrs }">
                            <span
                                v-bind="attrs"
                                v-on="on"
                            >
                                {{ book.title }}
                            </span>
                        </template>
                        <span>{{ book.title }}</span>
                    </v-tooltip>
                </p>
            </v-row>
            <v-row justify="center" class="my-0 mx-4 py-0">
                <p class="text-center text-subtitle-2 author-text my-0 py-0">
                    {{ book.author }}
                </p>
            </v-row>
        </v-card>
    </v-card>
</template>

<script>
const defaultBook = {
    author: '',
    id: 0,
    resourceDir: '',
    title: '',
}

export default {
    props: {
        book: {
            type: Object,
            default: defaultBook,
        },
    },
    data: () => ({
        loaded: false,
    }),
    methods: {
        goToBook() {
            const { book } = this
            this.$router.push(`book/${book.id}`)
        },
        handleLoaded() {
            this.loaded = true
        },
    },
}
</script>

<style lang="css" scoped>
.author-text {
    color: #9f9f9f;
}
.book-img {
    display: block;
    height: 100%;
    width: 60%;
    z-index: 1;
}
.truncate-overflow {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    -webkit-box-orient: vertical;
}
</style>
